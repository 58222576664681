export default [
  {
    header: 'Console',
    icon: 'PackageIcon',
    // ACCESS CONTROL
    children: [
      {
        title: 'My Console',
        route: '',
        icon: 'InfoIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'MyConsole',
      },
      {
        title: 'Price Calculator',
        route: 'price-calculator',
        icon: 'DollarSignIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'PriceCalculator',
      },
    ],
  },
]
