<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <b-link
        class="nav-link"
        :href="linkWeb+'home'"
        target="_self"
      >
        <b>HOME</b>
      </b-link>
      <b-dropdown
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        text="Solutions"
        variant="flat-primary"
      >
        <b-dropdown-item
          :href="linkWeb+'jarvist'"
          target="_self"
        >
          <b>Jarvist (Visibility)</b>
        </b-dropdown-item>
        <b-dropdown-item
          :href="linkWeb+'jardist'"
          target="_self"
        >
          <b>Jardist (Distribution)</b>
        </b-dropdown-item>
        <b-dropdown-item
          :href="linkWeb+'jarvist'"
          target="_self"
        >
          <b>Jarcomm (Commerce)</b>
        </b-dropdown-item>
        <b-dropdown-item
          :href="linkWeb+'additional'"
          target="_self"
        >
          <b>Jarforce (Additional System)</b>
        </b-dropdown-item>
      </b-dropdown>
      <b-link
        class="nav-link"
        :href="linkWeb+'team'"
        target="_self"
      >
        <b>Our Leadership Team</b>
      </b-link>
      <b-link
        class="nav-link"
        :href="linkWeb+'company'"
        target="_self"
      >
        <b>Our Company</b>
      </b-link>
      <b-link
        class="nav-link"
        :href="linkWeb+'career'"
        target="_self"
      >
        <b>Career</b>
      </b-link>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-success"
        class="btn-icon rounded-circle"
        href="https://wa.me/6285828830911"
        target="_self"
      >
        <feather-icon icon="PhoneCallIcon" />
      </b-button>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!--      <locale />-->
      <cart-dropdown />
      <price-badge />
      <login-badge />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BButton,
  BCardText,
  BDropdown, BDropdownDivider, BDropdownItem,
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Locale from './components/Locale.vue'
import CartDropdown from './components/CartDropdown.vue'
import PriceBadge from './components/PriceBadge.vue'
import LoginBadge from './components/LoginBadge.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,

    // Navbar Components
    BButton,
    BNavbarNav,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BCardText,
    Locale,
    CartDropdown,
    UserDropdown,
    PriceBadge,
    LoginBadge,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      linkWeb: 'https://pitjarus.com/',
    }
  },

}
</script>
