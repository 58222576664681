export default [
  {
    title: 'Master',
    icon: 'CodesandboxIcon',
    children: [
      /* User */
      {
        title: 'User',
        icon: 'UserIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'master-user',
        children: [
          {
            title: 'User Web',
            route: 'master-user-web',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-user',
          },
          {
            title: 'User Mobile',
            route: 'master-user-mobile',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-user',
          },
        ],
      },

      /* Store */
      {
        title: 'Store',
        icon: 'TruckIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'master-store',
        children: [
          {
            title: 'Trade Type',
            route: 'master-rtm-level5',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Channel',
            route: 'master-rtm-level4',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Sub Channel',
            route: 'master-rtm-level3',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Account',
            route: 'master-rtm-level2',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Nation',
            route: 'master-geographic-level5',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Region',
            route: 'master-geographic-level4',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Sub Region',
            route: 'master-geographic-level3',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Area',
            route: 'master-geographic-level2',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Province',
            route: 'master-province',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'District',
            route: 'master-district',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Sub District',
            route: 'master-sub-district',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Urban District',
            route: 'master-urban',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Store Type',
            route: 'master-store-type',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          {
            title: 'Store',
            route: 'master-store',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store',
          },
          // {
          //   title: 'Store Config',
          //   route: 'master-store-config',
          //   // ACCESS CONTROL
          //   action: 'read',
          //   resource: 'master-store',
          // },
        ],
      },

      /* SKU */
      {
        title: 'SKU',
        icon: 'InfoIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'master-product',
        children: [
          {
            title: 'Division',
            icon: 'InfoIcon',
            route: 'master-product-level4',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product',
          },
          {
            title: 'Category',
            icon: 'InfoIcon',
            route: 'master-product-level3',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product',
          },
          {
            title: 'Brand',
            icon: 'InfoIcon',
            route: 'master-product-level2',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product',
          },
          {
            title: 'Produsen',
            icon: 'InfoIcon',
            route: 'master-produsen',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product',
          },
          {
            title: 'SKU',
            icon: 'InfoIcon',
            route: 'master-sku',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product',
          },
          // {
          //   title: 'SKU Config',
          //   icon: 'InfoIcon',
          //   route: 'master-sku-config',
          //   // ACCESS CONTROL
          //   action: 'read',
          //   resource: 'master-product',
          // },
          {
            title: 'SKU Competitor',
            icon: 'InfoIcon',
            route: 'master-sku-competitor',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product',
          },
        ],
      },

      /* Promo */
      {
        title: 'Promo',
        icon: 'TagIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'master-promo',
        children: [
          {
            title: 'Promo',
            route: 'master-promo',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-promo',
          },
          {
            title: 'Promo Type',
            route: 'master-promo-property',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-promo',
          },
          // {
          //   title: 'Promo Config',
          //   route: 'master-promo-config',
          //   // ACCESS CONTROL
          //   action: 'read',
          //   resource: 'master-promo',
          // },
          // {
          //   title: 'Promo Mapping',
          //   route: 'master-promo-mapping',
          //   // ACCESS CONTROL
          //   action: 'read',
          //   resource: 'master-promo',
          // },
        ],
      },

      /* Activation */
      {
        title: 'Activation',
        icon: 'ShieldIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'master-activation',
        children: [
          {
            title: 'Activation',
            route: 'master-activation',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-activation',
          },
        ],
      },
      {
        title: 'Mapping',
        icon: 'GitBranchIcon',
        // ACCESS CONTROL
        action: 'read',
        resource: 'master-mapping',
        children: [
          {
            title: 'Store Mapping',
            route: 'master-store-mapping',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-store-mapping',
          },
          {
            title: 'SKU Mapping',
            icon: 'InfoIcon',
            route: 'master-sku-mapping',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product-mapping',
          },
          {
            title: 'SKU Sales Order Mapping',
            icon: 'InfoIcon',
            route: 'master-sku-so-mapping',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-product-so',
          },
          {
            title: 'Promo Mapping',
            route: 'master-promo-mapping',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-promo-mapping',
          },
          {
            title: 'Activation Mapping',
            route: 'master-activation-mapping',
            // ACCESS CONTROL
            action: 'read',
            resource: 'master-activation-mapping',
          },
        ],
      },
    ],
  },
]
