export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    // ACCESS CONTROL
    action: 'read',
    resource: 'dashboard',
  },
]
