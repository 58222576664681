export default [
  {
    title: 'Finance',
    route: 'finance',
    icon: 'CreditCardIcon',
    // ACCESS CONTROL
    action: 'read',
    resource: 'finance',
  },
]
