export default [
  {
    title: 'Usage Info',
    route: '',
    icon: 'InfoIcon',
    // ACCESS CONTROL
    action: 'read',
    resource: 'usage-info',
  },
]
