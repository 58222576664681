<template>
  <div
    v-if="isNotLogin"
    class="d-flex mb-0 mr-25"
  >
    <b-link
      :to="{ name: 'auth-login' }"
      class="alert-link text-info"
    >
      <span>LOGIN</span>
    </b-link>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { isUserLoggedIn } from '@/auth/utils'
import { BLink } from 'bootstrap-vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BLink,
  },
  computed: {
    isNotLogin() {
      return isUserLoggedIn() === null
    },
  },
}
</script>
