<template>
  <div class="d-flex mb-0 mr-25">
    <h6 class="font-weight-bolder mb-0">
      IDR {{ convertNumber($store.state['app-ecommerce'].priceTotal) }}
    </h6>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { numberWithCommas } from '@/@fake-db/utils'

export default {
  directives: {
    Ripple,
  },
  methods: {
    convertNumber(x) {
      return numberWithCommas(x)
    },
  },
}
</script>
