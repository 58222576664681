export default [
  {
    title: 'Billing',
    route: 'billing',
    icon: 'CreditCardIcon',
    // ACCESS CONTROL
    action: 'read',
    resource: 'billing',
  },
]
