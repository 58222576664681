<template>
  <b-nav-item-dropdown
    class="dropdown-cart mr-25"
    menu-class="dropdown-menu-media"
    right
    @show="fetchItems"
  >
    <template #button-content>
      <feather-icon
        :badge="$store.state['app-ecommerce'].cartModulesCount"
        class="text-body"
        icon="ShoppingCartIcon"
        size="21"
      />
    </template>

    <!--    &lt;!&ndash; Header &ndash;&gt;-->
    <!--    <li class="dropdown-menu-header">-->
    <!--      <div class="dropdown-header d-flex">-->
    <!--        <h4 class="notification-title mb-0 mr-auto">-->
    <!--          My Cart-->
    <!--        </h4>-->
    <!--        <b-badge-->
    <!--          pill-->
    <!--          variant="light-primary"-->
    <!--        >-->
    <!--          {{ $store.state['app-ecommerce'].cartModulesCount }} Items-->
    <!--        </b-badge>-->
    <!--      </div>-->
    <!--    </li>-->

    <!--    &lt;!&ndash; Cart Items &ndash;&gt;-->
    <!--    <vue-perfect-scrollbar-->
    <!--      v-if="modules.length"-->
    <!--      :settings="perfectScrollbarSettings"-->
    <!--      class="scrollable-container media-list scroll-area"-->
    <!--      tagname="li"-->
    <!--    >-->
    <!--      <div-->
    <!--        v-for="module in modules"-->
    <!--        :key="module.id"-->
    <!--      >-->
    <!--        <b-media-->
    <!--          v-for="product in filterCart(module.products)"-->
    <!--          :key="product.name+module.id+product.id"-->
    <!--        >-->
    <!--          <template #aside>-->
    <!--            <b-img-->
    <!--              :src="product.image"-->
    <!--              :alt="product.name"-->
    <!--              rounded-->
    <!--              width="62px"-->
    <!--            />-->
    <!--          </template>-->
    <!--          <feather-icon-->
    <!--            v-if="!product.mandatory"-->
    <!--            icon="XIcon"-->
    <!--            class="cart-item-remove cursor-pointer"-->
    <!--            @click.stop="removeItemFromCart(module,product)"-->
    <!--          />-->
    <!--          <div class="media-heading">-->
    <!--            <h6 class="cart-item-title">-->
    <!--              {{ product.name }}-->
    <!--            </h6>-->
    <!--          </div>-->

    <!--          <div-->
    <!--            v-if="product.maxQty > 1"-->
    <!--            class="cart-item-qty ml-1"-->
    <!--          >-->
    <!--            <b-form-spinbutton-->
    <!--              v-model.number="product.qty"-->
    <!--              :min="1"-->
    <!--              :max="product.maxQty"-->
    <!--              @input="updatePrice"-->
    <!--              size="sm"-->
    <!--            />-->
    <!--          </div>-->

    <!--          <h5 class="cart-item-price">-->
    <!--            IDR {{ convertNumber(product.price*product.qty) }}-->
    <!--          </h5>-->
    <!--        </b-media>-->
    <!--      </div>-->
    <!--    </vue-perfect-scrollbar>-->

    <!--    &lt;!&ndash; Cart Footer &ndash;&gt;-->
    <!--    <li-->
    <!--      v-if="$store.state['app-ecommerce'].cartModulesCount > 0"-->
    <!--      class="dropdown-menu-footer"-->
    <!--    >-->
    <!--      <div class="d-flex justify-content-between mb-1">-->
    <!--        <h6 class="font-weight-bolder mb-0">-->
    <!--          Total:-->
    <!--        </h6>-->
    <!--        <h6 class="text-primary font-weight-bolder mb-0">-->
    <!--          IDR {{ totalAmount }}-->
    <!--        </h6>-->
    <!--      </div>-->
    <!--      <b-button-->
    <!--        v-ripple.400="'rgba(255, 255, 255, 0.15)'"-->
    <!--        variant="primary"-->
    <!--        block-->
    <!--        :to="{ name: 'personal-info' }"-->
    <!--      >-->
    <!--        Checkout-->
    <!--      </b-button>-->
    <!--    </li>-->

    <!--    <p-->
    <!--      v-if="!modules.length"-->
    <!--      class="m-0 p-1 text-center"-->
    <!--    >-->
    <!--      Your cart is empty-->
    <!--    </p>-->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import { numberWithCommas, updatePrice } from '@/@fake-db/utils'
import store from '@/store'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modules: [],
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    totalAmount() {
      return numberWithCommas(store.state['app-ecommerce'].priceTotal)
    },
  },
  methods: {
    convertNumber(x) {
      return numberWithCommas(x)
    },
    fetchItems() {
      this.modules = JSON.parse(localStorage.getItem('modulesCart')) ?? []
    },
    filterCart(products) {
      return products.filter(e => e.isInCart)
    },
    updatePrice() {
      return updatePrice(this)
    },
    removeItemFromCart(module, product) {
      this.modules.find(e => e.id === module.id).products.find(e => e.id === product.id).isInCart = !this.modules.find(e => e.id === module.id).products.find(e => e.id === product.id).isInCart
      localStorage.setItem('modulesCart', JSON.stringify(this.modules))
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
